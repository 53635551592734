import React, { useState, useEffect, useRef } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination,
    PaginationItem,
    PaginationLink, Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import toast, { Toaster } from 'react-hot-toast';
import ReactPaginate from "react-paginate";
// import { URL } from "../../Apiurls";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, getAllWebData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import { CSVLink } from "react-csv"
import { useReactToPrint } from "react-to-print"

const Leads = () => {
    const [banner, setbanner] = useState([])
    // get all function

    const getAllbenners = async () => {
        const resonse = await addData("getallnewsletter")
        var _data = resonse
        setbanner(_data.data.newsLetters)
    }

    useEffect(() => {
        getAllbenners();
    }, []);


    const [listPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);

    const pagesVisited = pageNumber * listPerPage;
    const lists = banner.slice(pagesVisited, pagesVisited + listPerPage);
    const pageCount = Math.ceil(banner.length / listPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    const [forms, setforms] = useState([]);
    console.log(forms)

    // Search fuction
    const handleSearch = async (e) => {
        const resonse = await addData("getallnewsletter?searchQuery=" + e.target.value)
        var _data = resonse
        setbanner(_data.data.newsLetters)
    };

    // excel
    const csvReport = {
        filename: "Leads.csv",
        // headers: headers,
        data: banner,
    }
    // pdf 
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })


    
    const deletebenners = async (data) => {
        try {
            const resonse = await deletedData("deletenewsletter/" + data._id, {})
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            getAllbenners()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const manageDelete = (data) => {
        const confirmBox = window.confirm("Do you want to delete this item?");
        if (confirmBox === true) {
            deletebenners(data);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="INDFAME" breadcrumbItem="Newsletters" />
                    <Row>

                        <Col md={12}>
                            <Card>
                                <CardBody >
                                    <Row>
                                        <Col md="6">
                                            <div style={{ float: "left" }}>
                                                <Input
                                                    type="text"
                                                    name="search"
                                                    onChange={handleSearch}
                                                    className="form-control" placeholder="Search.." />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            {/* <div style={{ float: "right" }}>
                                                <CSVLink {...csvReport}>
                                                    <Button type="button" color="success m-1" outline>
                                                        Excel <i className="fas fa-file-excel"></i>
                                                    </Button>
                                                </CSVLink>
                                                <Button
                                                    onClick={handlePrint}
                                                    type="button"
                                                    color="danger m-1"
                                                    outline
                                                >
                                                    PDF <i className="fas fa-file-pdf"></i>
                                                </Button>
                                            </div> */}
                                        </Col>
                                    </Row>

                                    <div>
                                        <div className="table-responsive">
                                            <div ref={componentRef} >
                                            <Table className="table table-bordered mb-4 mt-3">
                                                <thead>
                                                    <tr>
                                                        <th>S No</th>
                                                        <th>Date</th>
                                                        <th>Email</th>
                                                        <th>Action</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lists.map((data, key) => (
                                                        <tr key={key}>
                                                            <td> {(pageNumber - 1) * 10 + key + 11}</td>
                                                            <td style={{width:"100px"}}>{data.logModifiedDate.slice(0, 10)}</td>
                                                            <td>{data.email}</td>
                                                            <td>
                                                            <Button
                                                                            onClick={() => {
                                                                                manageDelete(data);
                                                                            }}
                                                                            style={{ padding: "6px", margin: "3px" }} color="danger" outline>
                                                                            <i className="bx bx-trash"></i>
                                                                        </Button></td>
                                                       
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            </div>
                                            <div className="mt-3" style={{ float: "right" }}>

                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount}
                                                    onPageChange={changePage}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"disabled"}
                                                    activeClassName={"active"}
                                                    total={lists.length}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </Container>

                <Toaster />
            </div>
        </React.Fragment >
    )
}

export default Leads
