import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Input,
  Button,
  Modal,
  Form,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// import { URL } from "Apiurls"
import toast, { Toaster } from 'react-hot-toast';
// import { ToastContainer, toast } from "react-toastify"
import { addData, updateData } from "Servicescalls"
import { imgUrl } from "Baseurls"

function Aboutus() {
  const [form, setform] = useState([])

  console.log(form)


  const getTerms = async () => {
    const resonse = await addData("aboutus/getaboutus")
    var _data = resonse
    setform(_data.data.aboutus)
  }


  useEffect(() => {
    getTerms()
  }, [])

  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const [text1, setText1] = useState([])
  const [text2, setText2] = useState([])

  const getpopup1 = (form) => {
    setText1(form.title)
    setText2(form.description)
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()
    changstatus()
  }

  const [Files, setFiles] = useState({ image: "" });

  const changeHandler = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast.error('Please select a valid image file (jpg, jpeg, or png)')
    }
  };


  const changstatus = async () => {
    const dataArray = new FormData();
    dataArray.append("title", text1);
    dataArray.append("description", text2);
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i]);
    }
    try {
      const resonse = await updateData("aboutus/editaboutus", dataArray)
      var _data = resonse
      toast.success(_data.data.message)
      setmodal_small(false)
      getTerms()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="INDFAME" breadcrumbItem="About Us" />
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white"></CardHeader>

                <CardBody>
                  <div style={{ float: "right" }}>

                    <Button
                      style={{ width: "70px" }}
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Edit Booking"
                      onClick={() => {
                        getpopup1(form)
                      }}
                      className="mr-5"
                      color="primary"
                      outline
                    >
                      <span>Edit</span>
                    </Button>
                  </div>
                  <div>
                    <Row>
                      <Col md="4">
                        <div>
                          <h5>Image</h5>
                          <img src={imgUrl + form.image} className="w-100" />
                        </div>
                      </Col>
                      <Col md="12">
                        <div>
                          <h5>Content 1</h5>
                          <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                              __html: form.title,
                            }}
                          ></div>
                        </div>
                      </Col>
                      <Col md="12">
                        <div>
                          <h5>Content 2</h5>
                          <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                              __html: form.description,
                            }}
                          ></div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Modal
            size="lg"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit About Us
              </h5><button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <Form
              onSubmit={e => {
                submibooking(e)
              }}
            >
              <div className="modal-body">
                <div className="mb-3">
                  <Row>
                    <Col>
                      <Label for="basicpill-firstname-input1">
                        Image
                      </Label>
                    </Col>
                    <Col>
                      <Label style={{ float: "right" }} className="text-danger">
                        460 × 370 px
                      </Label>
                    </Col>
                  </Row>

                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    accept=".jpg, .jpeg, .png"
                    name="image"
                    onChange={changeHandler}
                  />
                </div>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Content 1
                  </Label>
                  <CKEditor
                    editor={ClassicEditor}
                    id="title"
                    data={text1}
                    onReady={editor => {
                      console.log("Editor is ready to use!", editor)
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setText1(data)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Content 2
                  </Label>
                  <CKEditor
                    editor={ClassicEditor}
                    id="description"
                    data={text2}
                    onReady={editor => {
                      console.log("Editor is ready to use!", editor)
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setText2(data)
                    }}
                  />
                </div>
              </div>

              <hr></hr>
              <div style={{ float: "right" }} className="m-2">
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
              </div>
            </Form>
          </Modal>
        </Container>
        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Aboutus