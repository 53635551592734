import React from "react"
import { Redirect } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/user-profile"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// PEG
// import Users from "pages/Indfame/Users"
// import Customers from "pages/Indfame/Customers"

import Banner from "pages/Indfame/Event"
import Faq from "pages/Indfame/Faq"
import Contact from "pages/Indfame/Contact"

// T&C
import Termsandconditions from "pages/Indfame/TermsandConditions"
import Privacy from "pages/Indfame/PrivacyPolicy"
// import Refering from "pages/Indfame/ReferingPolicy"
// import Customerdetails from "pages/Indfame/Customerdetails"
// import Blockedusers from "pages/Indfame/Blockedusers"
// import Userdetails from "pages/Indfame/Userdetails"
// import Customersrep from "pages/Indfame/reports/Customer"
// import Agentpayout from "pages/Indfame/reports/Agentpayout"
import Transactions from "pages/Indfame/reports/Transactions"
import Aboutus from "pages/Indfame/Aboutus"
import Ceonote from "pages/Indfame/Ceonote"
import Artgallery from "pages/Indfame/Artgallery"
import Schools from "pages/Indfame/Schools"
import Staff from "pages/Indfame/Staff"
import Testimonials from "pages/Indfame/Testimonials"
import Advisory from "pages/Indfame/Advisory"
import Leads from "pages/Indfame/Leads"
import Newsletter from "pages/Indfame/Newsletter"
import Coursnames from "pages/Indfame/Coursnames"
import Courses from "pages/Indfame/Courses"
import Titles from "pages/Indfame/Titles"
import Telangana from "pages/Indfame/Telangana"
import Feast from "pages/Indfame/Feast"

import ArtDialogues from "pages/Indfame/ArtDialogues"
import ArtVideos from "pages/Indfame/ArtVideos"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Otp from "pages/Authentication/Otp"
import Setpwd from "pages/Authentication/Setpwd"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  // { path: "/users", component: Users },
  // { path: "/users_details", component: Userdetails },
  // { path: "/customers", component: Customers },
  // { path: "/customer_details", component: Customerdetails },
  // { path: "/blockedusers", component: Blockedusers },
  { path: "/banners", component: Banner },

  { path: "/artdialogues", component: ArtDialogues },
  { path: "/artvideos", component: ArtVideos },

  { path: "/faq", component: Faq },
  { path: "/contact", component: Contact },

  { path: "/termsandconditions", component: Termsandconditions },
  { path: "/privacypolicy", component: Privacy },
  // { path: "/refundpolicy", component: Refering },
  // { path: "/agentpayout", component: Agentpayout },
  { path: "/transactions", component: Transactions },
  { path: "/aboutus", component: Aboutus },
  { path: "/ceonote", component: Ceonote },
  { path: "/artgallery", component: Artgallery },
  { path: "/schools", component: Schools },
  { path: "/staff", component: Staff },
  { path: "/testimonials", component: Testimonials },
  { path: "/advisory", component: Advisory },
  { path: "/contactdetails", component: Contact },
  { path: "/leads", component: Leads },
  { path: "/newsletter", component: Newsletter },
  { path: "/coursnames", component: Coursnames },
  { path: "/courses", component: Courses },
  { path: "/alltitles", component: Titles },
  { path: "/govtoftelangana", component: Telangana },
  { path: "/feast", component: Feast },

  // PEG
  // { path: "/category", component: Category },
  // { path: "/subcategory", component: Subcategory },
  // { path: "/books", component: Books },
  // { path: "/bookings", component: Bookings },
  // { path: "/pegs", component: Pegs },

  // { path: "/orderbooks", component: Orderbooks },
  // { path: "/rejectedbooks", component: Rejectedbooks },
  // { path: "/completed", component: Completed },
  // { path: "/pegview", component: Pegview },
  // { path: "/scheduledpegs", component: Scheduledpegs },
  // { path: "/cancelledpegs", component: Cancelledpegs },
  // { path: "/completedpegs", component: Completedpegs },
  // { path: "/bookingstatus", component: Bookingstatus },

  // { path: "/wallet", component: Wallet },
  // { path: "/requestedpayments", component: Requestedpayments },
  // { path: "/rejectedpayments", component: Rejectedpayments },
  // { path: "/Completedpayments", component: Completedpayments },

  // Reports
  // { path: "/customer_reports", component: Customersrep },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/otp", component: Otp },
  { path: "/setpassword", component: Setpwd },
]

export { publicRoutes, authProtectedRoutes }
