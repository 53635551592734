import React, { useState, useEffect } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination,
    PaginationItem,
    PaginationLink, Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import toast, { Toaster } from 'react-hot-toast';
import ReactPaginate from "react-paginate";
// import { URL } from "../../Apiurls";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData, getAllData } from "Servicescalls"
import { imgUrl } from "Baseurls"

const Contact = () => {
    const [form, setform] = useState([])
    const history = useHistory();
    const [Files, setFiles] = useState({ logo: "" });

    const handleChange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        setform(myUser);
    };


    const [items, setItems] = useState([]);
    const [userinfo, setuserinfo] = useState([]);
    console.log(items.token)
    console.log(userinfo)

    // get all function

    const getAllbenners = async () => {
        const resonse = await addData("title/gettitle")
        var _data = resonse
        setform(_data.data.title)
    }

    useEffect(() => {
        getAllbenners();
    }, []);

    // Edit fuction
    const editbenners = async (e) => {
        e.preventDefault()

        const bodydata = {
            aboutus: form.aboutus,
            ceonote: form.ceonote,
            classWiseCourse: form.classWiseCourse,
            artGallery: form.artGallery,
            schools: form.schools,
            testimonial: form.testimonial,
            advisory: form.advisory,
            crew: form.crew,
            feast: form.feast,
            news: form.news,
            contactus: form.contactus,
            faqs: form.faqs,
            termsAndCondition: form.termsAndCondition,
            privacyPolicy: form.privacyPolicy,
        }

        try {
            const resonse = await updateData("title/edittitle/", bodydata)
            var _data = resonse
            toast.success(_data.data.message)
            getAllbenners()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                // toast.error("An error occurred. Please try again.")
            }
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="INDFAME" breadcrumbItem="All Titles" />
                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    <Row>
                        <Col md={12}>
                            <Card>
                                {/* <CardHeader className="bg-white">
                                        <CardTitle>Add Testimonial</CardTitle>
                                    </CardHeader> */}
                                <CardBody >

                                    <Form onSubmit={(e) => {
                                        editbenners(e);
                                    }}>
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        About Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="aboutus"
                                                        value={form.aboutus}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        CEO Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="ceonote"
                                                        value={form.ceonote}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Courses Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="classWiseCourse"
                                                        value={form.classWiseCourse}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Gallery Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="artGallery"
                                                        value={form.artGallery}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Schools Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="schools"
                                                        value={form.schools}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Testimonial Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="testimonial"
                                                        value={form.testimonial}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Advisory Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="advisory"
                                                        value={form.advisory}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Crew Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="crew"
                                                        value={form.crew}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Feast Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="feast"
                                                        value={form.feast}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        News Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="news"
                                                        value={form.news}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Contact Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="contactus"
                                                        value={form.contactus}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Faq Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="faqs"
                                                        value={form.faqs}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Terms And Condition Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="termsAndCondition"
                                                        value={form.termsAndCondition}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Privacy Policy Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        required
                                                        name="privacyPolicy"
                                                        value={form.privacyPolicy}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>



                                        </Row>
                                        <div className="mt-4" style={{ float: "right" }}>
                                            <Button className="m-1" color="success" type="submit">
                                                Submit <i className="fas fa-check-circle"></i>
                                            </Button>
                                            {/* <Button className="m-1" onClick={() => { showaddevent() }} color="danger" type="button">
                                                    Cancel <i className="bx bx-x-circle"></i>
                                                </Button> */}

                                        </div>
                                    </Form>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                <Toaster />
            </div>
        </React.Fragment >
    )
}

export default Contact
