import React, { useState, useEffect } from "react";
import {
    CardBody, CardHeader, Container,
    Row, Col, Card, CardText, CardTitle,
    Form, Label, Input, Button, Table,
    Pagination,
    PaginationItem,
    PaginationLink, Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import toast, { Toaster } from 'react-hot-toast';
import ReactPaginate from "react-paginate";
// import { URL } from "../../Apiurls";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData, getAllData } from "Servicescalls"
import { imgUrl } from "Baseurls"

const Contact = () => {
    const [form, setform] = useState([])
    const history = useHistory();
    const [Files, setFiles] = useState({ logo: "" });

    const handleChange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        setform(myUser);
    };

    const changeHandler = (e) => {
        const file = e.target.files
        var ext = file[0].name.split(".").pop()
        var type = ext
        if (type == "jpg" || type == "jpeg" || type == "png") {
            setFiles(e.target.files)
        } else {
            e.target.value = null
            toast.error('Please select a valid image file (jpg, jpeg, or png)')
        }
    };


    const [items, setItems] = useState([]);
    const [userinfo, setuserinfo] = useState([]);
    console.log(items.token)
    console.log(userinfo)

    // get all function

    const getAllbenners = async () => {
        const resonse = await addData("govt/getgovt")
        var _data = resonse
        setform(_data.data.govt)
    }

    useEffect(() => {
        getAllbenners();
    }, []);

    // Edit fuction
    const editbenners = async (e) => {
        e.preventDefault()
        const dataArray = new FormData();
        dataArray.append("name", form.name);
        dataArray.append("description", form.description);
        for (let i = 0; i < Files.length; i++) {
            dataArray.append("image", Files[i]);
        }

        try {
            const resonse = await updateData("govt/editgovt", dataArray)
            var _data = resonse
            toast.success(_data.data.message)
            getAllbenners()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                // toast.error("An error occurred. Please try again.")
            }
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="INDFAME" breadcrumbItem="Govt. Of Telangana" />
                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    <Row>
                        <Col md={12}>
                            <Card>
                                {/* <CardHeader className="bg-white">
                                        <CardTitle>Add Testimonial</CardTitle>
                                    </CardHeader> */}
                                <CardBody >

                                    <Form onSubmit={(e) => {
                                        editbenners(e);
                                    }}>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Title
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        name="name"
                                                        value={form.name}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Row>
                                                        <Col >
                                                            <Label for="basicpill-firstname-input1">
                                                                Image
                                                            </Label>
                                                        </Col>
                                                        <Col >
                                                            <Label style={{ float: "right" }} className="text-danger">
                                                                150 × 150 px
                                                            </Label>
                                                        </Col>
                                                    </Row>
                                                    <Input
                                                        type="file"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Title"
                                                        name="image"
                                                        // value={form.title}
                                                        onChange={changeHandler}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Description
                                                    </Label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Description"

                                                        name="description"
                                                        value={form.description}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>


                                        </Row>
                                        <div className="mt-4" style={{ float: "right" }}>
                                            <Button className="m-1" color="success" type="submit">
                                                Submit <i className="fas fa-check-circle"></i>
                                            </Button>
                                            {/* <Button className="m-1" onClick={() => { showaddevent() }} color="danger" type="button">
                                                    Cancel <i className="bx bx-x-circle"></i>
                                                </Button> */}

                                        </div>
                                    </Form>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                <Toaster />
            </div>
        </React.Fragment >
    )
}

export default Contact
