import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link, useHistory } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Button } from "reactstrap"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  let history = useHistory()

  const logout = () => {
    localStorage.clear()
    history.push("/logout")
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li style={{paddingTop:"1px"}}>
              <Link to="/dashboard">
                <i className="bx bx-store"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
          
            <li style={{paddingTop:"1px"}}>
              <Link to="/leads">
                <i className="bx bx-file"></i>
                <span>{props.t("Leads")}</span>
              </Link>
            </li>
            
            <li style={{paddingTop:"1px"}}>
              <Link to="/newsletter">
                <i className="bx bx-envelope"></i>
                <span>{props.t("Newsletters")}</span>
              </Link>
            </li>
            <li style={{paddingTop:"1px"}}>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-book-open"></i>
                <span>{props.t("Courses")}</span>
              </Link>
              <ul className="sub-menu">
              
                <li style={{paddingTop:"1px"}}>
                  <Link to="/coursnames">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("Course Names")}</span>
                  </Link>
                </li>
                <li style={{paddingTop:"1px"}}>
                  <Link to="/courses">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("Courses")}</span>
                  </Link>
                </li>

              </ul>
            </li>
            <li style={{paddingTop:"1px"}}>
              <Link to="/artdialogues">
                <i className="bx bx-carousel"></i>
                <span>{props.t("Home Art Gallery")}</span>
              </Link>
            </li>
            <li style={{paddingTop:"1px"}}>
              <Link to="/artgallery">
                <i className="bx bxs-palette"></i>
                <span>{props.t("Art Gallery")}</span>
              </Link>
            </li>
            <li style={{paddingTop:"1px"}}>
              <Link to="/artvideos">
                <i className="bx bx-video-recording"></i>
                <span>{props.t("Art Videos")}</span>
              </Link>
            </li>
            <li style={{paddingTop:"1px"}}>
              <Link to="/feast">
                <i className="bx bx-image-add"></i>
                <span>{props.t("Feast")}</span>
              </Link>
            </li>
            <li style={{paddingTop:"1px"}}>
              <Link to="/schools">
                <i className="bx bxs-school"></i>
                <span>{props.t("Schools")}</span>
              </Link>
            </li>
            <li style={{paddingTop:"1px"}}>
              <Link to="/advisory">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Advisory")}</span>
              </Link>
            </li>
            <li style={{paddingTop:"1px"}}>
              <Link to="/staff">
                <i className="bx bx-user-pin"></i>
                <span>{props.t("Staff")}</span>
              </Link>
            </li>
            <li style={{paddingTop:"1px"}}>
              <Link to="/testimonials">
                <i className="bx bx-comment-dots"></i>
                <span>{props.t("Testimonials")}</span>
              </Link>
            </li>
       
            <li style={{paddingTop:"1px"}}>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-slider-alt"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">
                <li style={{paddingTop:"1px"}}>
                  <Link to="/banners">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("Home Banners")}</span>
                  </Link>
                </li>
                <li style={{paddingTop:"1px"}}>
                  <Link to="/aboutus">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("About Us")}</span>
                  </Link>
                </li>
                <li style={{paddingTop:"1px"}}>
                  <Link to="/ceonote">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("Ceo Note")}</span>
                  </Link>
                </li>
                <li style={{paddingTop:"1px"}}>
                  <Link to="/faq">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("FAQ")}</span>
                  </Link>
                </li>
                <li style={{paddingTop:"1px"}}>
                  <Link to="/contactdetails">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("Contact Details")}</span>
                  </Link>
                </li>
                <li style={{paddingTop:"1px"}}>
                  <Link to="/govtoftelangana">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("Govt. Of Telangana")}</span>
                  </Link>
                </li>
                <li style={{paddingTop:"1px"}}>
                  <Link to="/alltitles">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("All Titles")}</span>
                  </Link>
                </li>
                <li style={{paddingTop:"1px"}}>
                  <Link to="/termsandconditions">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("Termsandconditions")}</span>
                  </Link>
                </li>
                <li style={{paddingTop:"1px"}}>
                  <Link to="/privacypolicy">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("Privacy Policy")}</span>
                  </Link>
                </li>

              </ul>
            </li>

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
